import { DEFAULT_ENABLED_GENERAL_REPORT_FIELD, GeneralReportEnabledField } from "domain/entity/GeneralReport/GeneralReportEnabledField";
import { EMPTY_GENERAL_REPORT_ENTITY, GeneralReportEntity } from "domain/entity/GeneralReport/GeneralReportEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface GeneralReportDropdownOptions {
    reportTypeDropdownOptions: DropdownProps[],
    printQueueDropdownOptions: DropdownProps[],
    fileLocationDropdownOptions: DropdownProps[],
    companyCodeDropdownOptions: DropdownProps[],
    chargeOnCompanyDropdownOptions: DropdownProps[],
    masterCompanyCodeDropdownOptions: DropdownProps[],
    chargeTypeDropdownOptions: DropdownProps[],
    tariffTypeDropdownOptions: DropdownProps[],
    tariffNatureDropdownOptions: DropdownProps[],
    customerCodeDropdownOptions: DropdownProps[],
    proposalNoFromDropdownOptions: DropdownProps[],
    proposalNoToDropdownOptions: DropdownProps[],
    proposalNoDropdownOptions: { [key: string]: DropdownProps[] },
    billingClerkDropdownOptions: DropdownProps[],
    yearDropdownOptions: DropdownProps[],
    monthDropdownOptions: DropdownProps[],
    criteriaTypeDropdownOptions: DropdownProps[],
    criteriaNameDropdownOptions: DropdownProps[],
}
export interface GeneralReportViewChangeState extends BaseViewChangeSate {
}

export interface GeneralReportModel {
    isLoading: boolean,
    reportIdList: string[],
    reportTypeList: string[],
    printQueueIdMap: { [key: string]: number },
    reportIdTypeMap: { [key: string]: string },
    criteriaTypeIdMap: { [key: string]: string },
    dynamicOptions: GeneralReportDropdownOptions,
    currentReport: GeneralReportEntity,
    enabledField: GeneralReportEnabledField,

}

export const EMPTY_GENERAL_REPORT_MODEL: GeneralReportModel = {
    isLoading: false,
    dynamicOptions: {
        reportTypeDropdownOptions: [],
        printQueueDropdownOptions: [],
        fileLocationDropdownOptions: [],
        companyCodeDropdownOptions: [],
        chargeTypeDropdownOptions: [],
        tariffTypeDropdownOptions: [],
        tariffNatureDropdownOptions: [],
        customerCodeDropdownOptions: [],
        chargeOnCompanyDropdownOptions: [],
        masterCompanyCodeDropdownOptions: [],
        billingClerkDropdownOptions: [],
        yearDropdownOptions: [],
        monthDropdownOptions: [],
        criteriaTypeDropdownOptions: [],
        criteriaNameDropdownOptions: [],
        proposalNoFromDropdownOptions: [],
        proposalNoToDropdownOptions: [],
        proposalNoDropdownOptions: {}
    },
    printQueueIdMap: {},
    reportTypeList: [],
    currentReport: { ...EMPTY_GENERAL_REPORT_ENTITY },
    enabledField: { ...DEFAULT_ENABLED_GENERAL_REPORT_FIELD },
    reportIdTypeMap: {},
    reportIdList: [],
    criteriaTypeIdMap: {}
}